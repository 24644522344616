import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    HostListener,
    inject,
    PLATFORM_ID,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DealerFeature } from '@hyundai/ng-common-lib';
import { M040C005ImprintNoticeComponent } from '@hyundai/ui';
import { Store } from '@ngrx/store';
import { FooterPwComponent } from '../footer-pw/footer-pw.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { NavWrapperComponent } from '../nav/nav-wrapper.component';

@Component({
    selector: 'app-partner-web',
    standalone: true,
    imports: [
        RouterOutlet,
        FooterPwComponent,
        NavBarComponent,
        M040C005ImprintNoticeComponent,
        M040C005ImprintNoticeComponent,
        NavWrapperComponent,
    ],
    templateUrl: './partner-web.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerWebComponent {
    store = inject(Store);
    platformId = inject(PLATFORM_ID);
    renderer = inject(Renderer2);
    document = inject(DOCUMENT);

    showMenu = this.store.selectSignal(DealerFeature.state.selectShowMenu);
    dealer = this.store.selectSignal(DealerFeature.state.selectLoadedDealer);
    richDealerData = this.store.selectSignal(DealerFeature.state.selectRichDealerData);
    socialLinks = this.store.selectSignal(DealerFeature.state.selectSocialLinks);

    @HostListener('click', ['$event.target'])
    handleFootnoteClick(target: HTMLElement | null) {
        if (!(target instanceof HTMLElement) || target.tagName !== 'SUP') return;

        const id = target.getAttribute('class')?.match(/(^|\s)f_(\w+)(\s|$)/)?.[2];
        if (!id) return;

        // eslint-disable-next-line @rx-angular/prefer-no-layout-sensitive-apis
        document.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth' });
    }

    setRichDealerDataEffect = effect(() => {
        const richDealerData = this.richDealerData();

        const script = this.renderer.createElement('script') satisfies HTMLScriptElement;
        script.type = 'application/ld+json';
        script.text = JSON.stringify(richDealerData);

        this.renderer.appendChild(this.document.body, script);
    });
}
