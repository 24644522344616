@defer (on immediate) {
    <app-nav-wrapper />
}

<div class="mx-auto max-w-[1920px] bg-white xl:border-grey-300 2xl:border-l 2xl:border-r">
    <app-nav-bar />

    <ui-m040-c005-imprint-notice [dealer]="dealer()" />

    <router-outlet />
    @defer (on immediate) {
        <app-footer-pw />
    }
</div>
